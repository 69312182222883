import * as React from 'react'

import LayoutRoot from '../components/LayoutRoot'
import Services from '../components/Services'
import ContactUs from '../components/ContactUs'

const ServiceList = ({ pageContext: { services } }: { pageContext: { services: Service[] } }) => {
  return (
    <LayoutRoot isWhiteCredential>
      <>
        <Services services={services} isDetail />
        <div style={{marginTop: 50}} />
        <ContactUs />
      </>
    </LayoutRoot>
  )
}
export default ServiceList
